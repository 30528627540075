import React from 'react';
import {Construction} from '../atoms/Construction'
import {Section} from '../atoms/Section'
import {Title,CatchCopy,Body} from '../atoms/Text'

const title = "ABOUT"
const catchCopy = ["想うより早く","アイディアを形に"]
const about = ["日々変化する情報技術に合わせて","最適なカタチを私たちは模索し続けます","あなたのアイディアを世界に届けるために"]

const About = () => {
  return(
    <Section id="about">
      <Title>
        {title}
      </Title>
      <CatchCopy>
        {catchCopy[0]}<br/>
        {catchCopy[1]}
      </CatchCopy>
      <Body>
        {about[0]}<br/>
        {about[1]}
      </Body>
    
    </Section>
  )
};

export default About;