import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import TopPage from './TopPage'
import {AppFrame} from '../templates/AppFrame';
import {MenuList} from '../molecules/MenuList';
import {AppLogo} from '../atoms/AppLogo';


const pages = [
    { path: '/', component: <TopPage/> },
  ];
  const menus = [
    { path: '/#about', text:'ABOUT'},
    { path: '/#works', text:'WORKS'},
    { path: '/#solution', text:'SOLUTION'},
    { path: '/#company', text:'COMPANY'},
    { path: '/#contact', text:'CONTACT'},
  ]


const Pages = ()=>{
    return(
      <Router>
      <AppFrame appLogo={<AppLogo />} menus={<MenuList menus={menus}/>}>
        {pages.map((item,index) => (
          <Route
            key={index}
            exact
            path={item.path}
            component={() => item.component}
          />
        ))}
      </AppFrame>
      </Router>
    )
  };
  export default Pages;
  