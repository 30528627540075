import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import styled from "styled-components"

export const Construction = (props) => {
  return(
    <Div>
        <P>Comming soon ...</P>
        <StyledProgress/>
    </Div>
  )
}

const Div = styled.div`
    width:100%;
    height:auto;
    margin-bottom:20px;
`;

const P = styled.p`
    width:100%;
    text-align:center;
`

const StyledProgress = styled(LinearProgress)`
    
`;