import React from 'react';
import {Section} from '../atoms/Section'
import {Title,Body} from '../atoms/Text'
import {Work} from '../molecules/Work'

const title = "WORKS"
const about = ["パートナーとして初期段階から事業に関わり","組織の一員として事業の発展に寄与します"]
const works = [
  { title:'インテリア関連企業様',
    category:'マーケティング事例',
    img:'',
    body:<>マーケティング施策のヒアリングから相談を実施。
    クライアントの意向を組みながら、最適な広告施策の提案を行い、
    実施までフルサポートしています。
    <br/><br/>また、新規施策およびシステムへのアップデートを合わせてご提案をしています。
</>
  }
]

const Works = () => {
  return(
    <Section id="works">
      <Title>
        {title}
      </Title>
      <Body>
        {about[0]}<br/>
        {about[1]}
      </Body>
      {works.map((item,index) => {
        return(
          <Work
            key={index}
            title={item.title}
            category={item.category}
          >
            {item.body}
          </Work>
        );
      })}
    </Section>
  )
};

export default Works;