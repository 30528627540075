import React from 'react';
import styled,{ keyframes } from "styled-components"
import logo from '../../images/logo.svg';

const rSpin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const lSpin = keyframes`
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
`;


export const RotateIcon = (props) => {
    return(
        <Div>
            <SSIcon src={logo}/>
            <SmallIcon src={logo}/>
            <MiddleIcon src={logo}/>
            <LargeIcon src={logo}/>
        </Div>);
}

const SSIcon = styled.img`
  position:absolute;
  animation: ${lSpin} infinite 300s linear;
  opacity:0.50;
  width:65%;
  top: -20vh;
  right: 0;
  left:calc(27% + ${props => props.theme.drawerWidth} / 4);
  bottom: 0;
  margin: auto;
  @media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
    width:35%;
    top:0;
    left:calc(${props => props.theme.drawerWidth});
  }
`

const SmallIcon = styled.img`
    position:absolute;
    animation: ${rSpin} infinite 200s linear;
    opacity:0.63;
    width:120%;
    top: -20vh;
    right: 0;
    left:calc(${props => props.theme.drawerWidth} / 4);
    bottom: 0;
    margin: auto;
    @media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
      width:65%;
      top:0;
      left:${props => props.theme.drawerWidth};
    }
`;

const MiddleIcon = styled.img`
    position:absolute;
    animation: ${lSpin} infinite 320s linear;
    opacity:0.72;
    width:210%;
    top: -20vh;
    left: calc(-50% + ${props => props.theme.drawerWidth} / 4);
    right: 0;
    bottom: 0;
    margin: auto;
    @media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
      width:120%;
      top:0;
      left: calc(-20% + ${props => props.theme.drawerWidth});
    }
`;

const LargeIcon = styled.img`
    position:absolute;
    animation: ${rSpin} infinite 120s linear;
    opacity:0.72;
    width:380%;
    top: -20vh;
    left:calc(-130% + ${props => props.theme.drawerWidth} / 4);
    right: 0;
    bottom: 0;
    margin: auto;
    @media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
      width:235%;
      top:0;
      left:calc(-75% + ${props => props.theme.drawerWidth});
    }
`;


const Div = styled.div `
    position:fixed;
    width:100%;
    top:0;
    z-index:-1;
    height:80vh;
    overflow:hidden;
    background:white;
    @media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
      height:100vh;
    }
`