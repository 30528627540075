import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import styled from "styled-components"
import {useHistory} from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';

export const MenuList = (props) => {
  const menus = props.menus ? props.menus : [];
  const history = useHistory();
  return(
     <StyledList component="nav" aria-label="main mailbox folders">
      {menus.map((item,index) => (
        <StyledItem onClick={()=> history.push(item.path)} key={index} button>
         <Text smooth  to={item.path}>{item.text}</Text>
       </StyledItem>
      ))}
     </StyledList>
  )
}

const Text = styled(Link)`
  text-decoration: none;
  color:white;
  margin-left:50px;
  font-size:20px;
  font-weight:700;
  line-height:24px;
`

const StyledItem = styled(ListItem)`
    height:60px;
`

const StyledList = styled(List)`
  margin-bottom:100px;
`