import React from 'react';
import styled from "styled-components"

export const Table = (props) => {
    const rows = props.rows ? props.rows :[];
    return(
        <table>
            <tbody>
                {rows.map((item,index) => (
                    <tr key={index}>
                    <Label>{item.label}</Label>
                    <Content>{item.content}</ Content>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

const Label = styled.td`
    color:white;
    min-width:100px;
`
const Content = styled.td`
    color:white;
`