import React from 'react';
import {Section} from '../atoms/Section'
import {Title,Body} from '../atoms/Text'
import {BasicButton} from '../atoms/Button' 

const title = "CONTACT"
const about = ["採用、事業に関するご相談は","以下リンクよりご連絡下さい"]
const href = "https://lin.ee/nrG4NcV"

const Contact = () => {
  return(
    <Section id="contact">
      <Title>
        {title}
      </Title>
      <Body>
        {about[0]}<br/>
        {about[1]}
      </Body>
      <BasicButton href={href}>
        CONTACT
      </BasicButton>
    </Section>
  )
};

export default Contact;