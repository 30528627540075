import React from 'react';
import {Section} from '../atoms/Section'
import {Title} from '../atoms/Text'
import {Table} from '../atoms/Table'

const title = "COMPANY"
const rows = [
    {label:"会社名",content:"合同会社SOCT"},
    {label:"設立",content:"2017年 10月"},
    {label:"所在地",content:"東京都 千代田区 九段南 1丁目 5番 6号 りそな九段ビル 5F"}
]

const Company = () => {
  return(
    <Section id="company">
        <Title>
            {title}
        </Title>
        <Table rows={rows}/> 
    </Section>
  )
};

export default Company;