import React from 'react';
import styled,{ keyframes } from "styled-components"
import Typography from '@material-ui/core/Typography';

export const Section = styled.section`
    display:flex;
    align-items: center;
    flex-direction:column;
    margin-top:100px;
    padding:20px;
    @media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
        margin-left:calc(${props => props.theme.drawerWidth} / 2);
    }
    
`