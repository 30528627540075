import React from 'react';
import appLogo from '../../images/appLogo.svg'
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import styled from "styled-components"

export const AppLogo = (props) => {
  return(
    <Div>
        <Img src={appLogo}/>
    </Div>
  )
}

const Div = styled.div`
    width:100%;
    height:200px;
`

const Img = styled.img`
    width:60%;
    margin:40px;
    top:40px;
`;