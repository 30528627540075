import React from 'react';
import styled from "styled-components"
import Typography from '@material-ui/core/Typography';

export const IconCard = (props) => {
    const icon = props.icon;
    const title = props.title;
    const content = props.content?props.content:[];
  return(
    <Div>
      <Icon src={icon}/>
      <Title>{title}</Title>
     <Body>{content[0]}<br/>{content[1]}</Body>
    </Div>
  )
}

const Div = styled.div`
    width:320px;
    height:300px;
    margin:30px;
    border:solid white;
    border-radius:60px;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items: center;
    > * {
        color:white;
     }
`

const Icon = styled.img`
  
        color:white;
        width:80px;
        height:80px;
 
`

const Title = styled(Typography).attrs({
    variant:'body1',
})`
    margin:${props => props.theme.spacing(2)}px 0;
    font-size:20px;
    font-weight:bold;
`

const Body = styled(Typography).attrs({
    variant:'body1',
})`
    margin:0;
    font-weight:700;
    text-align:center;
    color:#FFFFFF;
    line-height:1.8;
`