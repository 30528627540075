import React from 'react';
import styled,{ keyframes } from "styled-components"
import Hidden from '@material-ui/core/Hidden';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';


const fadeIn = keyframes`
    0% {
      opacity:0.1;
    }
    100% {
      opacity:1;
    }
`;

const StyledAppBar = styled(AppBar)`
  height:${props => props.theme.gutter.sp};
  background: rgba(0,0,0,0.2);
  backdrop-filter: blur(4px);
  color:white;
`;

const StyledIconButton = styled(IconButton)`
  @media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
    display: none;
  }
`

const AppName = styled.div`
  display: flex;
  align-items: center;
  width:100%;
  height:auto;
`

const DrawerArea = styled.div`
  position:fixed;
  top:0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width:${props => props.theme.drawerWidth};
  background: rgba(0,0,0,0.2);
  backdrop-filter: blur(4px);
  height:100vh;
  z-index:${props => props.theme.zIndex.drawer};
`;

const Overlay = styled.div`
  animation: ${fadeIn} 0.2s ease-in-out;
  backdrop-filter: blur(6px);
  position:fixed;
  top:0;
  width:100vw;
  height:100vh;
  z-index:${props => props.theme.zIndex.drawer};
`
const Content = styled.div`
  background:black;
  z-index:10;
  margin-top:80vh;
  padding-top:1px;
  @media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
    margin-top:100vh;
  }
`

const SimpleAppBar = (props) => {
  return(
    <StyledAppBar color="inherit">
     <Toolbar>
      <StyledIconButton
        onClick={()=>props.handleMenu()}
        edge="start" color="inherit" aria-label="menu">
        <MenuIcon/>
      </StyledIconButton>
        {props.children}
     </Toolbar>
    </StyledAppBar>
  )
}

export const AppFrame = (props) => {
  const [open, setOpen] = React.useState(true);
  const handleMenu = () => {
   setOpen(!open);
 };

  return(
    <>
      <Hidden smUp>
      <SimpleAppBar
        handleMenu={handleMenu}
        title={props.title}/>
        {open?
          <Overlay onClick={handleMenu}>
            <DrawerArea><AppName>{props.appLogo}</AppName>{props.menus}</DrawerArea>
          </Overlay>:null
        }
      </Hidden>
      <Hidden xsDown>
        <DrawerArea><AppName>{props.appLogo}</AppName>{props.menus}</DrawerArea>
      </Hidden>
      <Content>
        {props.children}
      </Content>
    </>
  );
}