import React from 'react';
import {RotateIcon} from '../atoms/RotateIcon'
import {Construction} from '../atoms/Construction'
import About from '../organisms/About'
import Works from '../organisms/Works'
import Solution from '../organisms/Solution'
import Company from '../organisms/Company'
import Contact from '../organisms/Contact'

const TopPage = () => {
  return(
    <div>
      <RotateIcon/>
      <About/>
      <Works/>
      <Solution/>
      <Company/>
      <Contact/>
    </div>
  )
};

export default TopPage;