import React,{useState} from 'react';
import {Title,Body} from '../atoms/Text';
import styled,{ keyframes } from "styled-components"

export const Work = (props) => {
  const [opened,setOpened] = useState(false);
  return(
    <Div>
      <Summary opened={opened} onClick={() => setOpened(!opened)}>
        <Category>
          {props.category}
        </Category>
        <SummaryTitle>        
          {props.title}
        </SummaryTitle>
      </Summary>
      {opened?
      <Detail opened={opened}>
        <LeftBody>{props.children}</LeftBody>
      </Detail>
      :null}
    </Div>
  );
};

const open = keyframes`
    0% {
      max-height:0;
    }
    100% {
      max-height:1000px;
    }
`;

const close = keyframes`
    0% {
      max-height1000px;
    }
    100% {
      max-height:0;
    }
`

const Category = styled(Body)`
  position:relative;
  margin:${props => props.theme.spacing(6)}px ${props => props.theme.spacing(4)}px 0;
  text-align:left;
  @media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
    margin:${props => props.theme.spacing(6)}px ${props => props.theme.spacing(8)}px 0;
  }
`

const SummaryTitle = styled(Title)`
  position:relative;
  margin:0 ${props => props.theme.spacing(4)}px;
  @media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
    margin:0 ${props => props.theme.spacing(8)}px;
  }
`;

const LeftBody = styled(Body)`
  text-align:left;
  margin:${props => props.theme.spacing(4)}px ${props => props.theme.spacing(4)}px ${props => props.theme.spacing(8)}px;
  @media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
    margin:${props => props.theme.spacing(8)}px;
  }
`
const Summary = styled.div`
    background:url("https://firebasestorage.googleapis.com/v0/b/soct-corp.appspot.com/o/1.jpg?alt=media&token=dc99197e-5c16-40b8-91d4-fe0c487ce721");
    background-size:cover;
    position:relative;
    z-index:1;
    width:100%;
    height:150px;
    display:inline-block;
    border-radius:75px;
    &:before {
      content:'';
      position:absolute;
      background-color: rgba(0, 0, 0, 0.2);
      backdrop-filter: blur(12px);
      border-radius:75px;
      border:solid white;
      top:0;
      left:0;
      width:100%;
      height:100%;
    }
    &:after {
      content:'↓';
      font-size:32px;
      font-weight:bold;
      line-height:32px;
      z-index:2;
      position:absolute;
      top:59px;
      right:32px;
      color:white;
      transform: ${props => props.opened?'rotate(-180deg)':null};
      transition: all 0.8s;
      @media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
        top:58px;
        right:80px;
      }
    }
`

const Detail = styled.div`
    animation: ${open} 0.8s ease-in-out;
    position:relative;
    z-index:0;
    width:100%;
    overflow:hidden;
    padding:80px 0 0;
    top:-80px;
    margin:0 0 -80px;
    border:solid white;
    border-radius:0 0 75px 75px;
`

const Div = styled.div`
  width:90%;
  max-width:700px;
  background:none;
  border:none;
  margin:${props => props.theme.spacing(2)}px 0;
`