import { createMuiTheme } from "@material-ui/core";

const theme = createMuiTheme({
  palette:{
    primary:{
      main:'#575757',
    }
  },
  typography: {
    fontSize: 14,
    fontFamily: [
      'Noto Sans JP',
      'sans-serif',
    ].join(','),
  },
  drawerWidth:'240px',
  tableHeight:'440px',
  gutter:{pc:'64px',sp:'56px'}
});

export {theme};

export const firebaseConfig = {
  apiKey: "AIzaSyDuYFZzHHzPPkKDkyQetTRRXSm8oLG1yxE",
  authDomain: "soct-corp.firebaseapp.com",
  databaseURL: "https://soct-corp.firebaseio.com",
  projectId: "soct-corp",
  storageBucket: "soct-corp.appspot.com",
  messagingSenderId: "1086171718136",
  appId: "1:1086171718136:web:74fc321d43e597af2ab60d",
  measurementId: "G-K59NDJL8ZH"
};