import React from 'react';
import styled,{ keyframes } from "styled-components"
import Typography from '@material-ui/core/Typography';

export const Title = styled(Typography).attrs({
    variant:'h6',
})`
    margin:${props => props.theme.spacing(2)}px 0;
    font-weight:700;
    color:#FFFFFF;
`

export const CatchCopy = styled(Typography).attrs({
    variant:'h4',
})`
margin:${props => props.theme.spacing(8)}px 0;
    color:#FFFFFF;
    text-align:center;
    text-shadow: 0 0 1px #fff,0 0 10px #fff;
    line-height:1.8;
`;

export const Body = styled(Typography).attrs({
    variant:'body1',
})`
    margin:${props => props.theme.spacing(2)}px 0;
    font-weight:700;
    text-align:center;
    color:#FFFFFF;
    line-height:1.8;
`