import React from 'react';
import {IconCard} from '../atoms/IconCard'
import {Section} from '../atoms/Section'
import {Title,Body} from '../atoms/Text'
import styled from "styled-components"
import prod from '../../images/icon_prod.svg'
import mkt from '../../images/icon_mkt.svg'
import data from '../../images/icon_data.svg'
import biz from '../../images/icon_biz.svg'

const title = "SOLUTION"
const about = ["IT技術を基軸としたプロダクト開発支援","各種コンサルティングを実施しています"]
const solutions = [
  {icon:prod,title:"プロダクト開発",content:["高品質で堅牢なサービスを","迅速に具現化します"]},
  {icon:mkt,title:"マーケティング支援",content:["あらゆる手段を駆使して","事業KPIの改善に寄与します"]},
  {icon:data,title:"データサイエンス",content:["サービスの分析基盤","学習モデルを提供します"]},
  {icon:biz,title:"事業デザイン",content:["サービス設計から組織作りまで","多角的に事業成長を支援します"]},
]

const Solution = () => {
  return(
    <Section id="solution">
      <Title>
        {title}
      </Title>
      <Body>
        {about[0]}<br/>
        {about[1]}
      </Body>
      <Div>
        {solutions.map((item,index) => (
          <IconCard key={index} icon={item.icon} title={item.title} content={item.content}/>
        ))}
    </Div>
    </Section>
  );
};

export default Solution;

const Div = styled.div`
    display:flex;
    flex-wrap:wrap;
    flex-direction: column;
    justify-content:center;
    align-items: center;
    width:100vw;
    @media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
      width:780px;
      flex-direction: row;
    }
`
